.custom-card-media {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-top-right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1; /* Ensure the badge is on top */
}